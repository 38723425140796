<template>
  <div class="panel">
    <v-row class="panel-options" justify="end">
      <slot name="options"></slot>
    </v-row>
    <Card :style="styleLine">
      <template slot="content-full">
        <slot name="content-full"></slot>
      </template>
      <slot></slot>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
export default {
  name: 'Panel',
  components: { Card },
  props: {
    lineColor: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    styleLine() {
      return this.lineColor
        ? {
            borderTop: `2px solid ${this.lineColor}`
          }
        : {}
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
}
</style>
