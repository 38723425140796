<template>
  <div class="page">
    <Panel lineColor="var(--primary-color)" class="text-start">
      <v-row>
        <v-col cols="auto" class="px-10" align-self="center">
          <v-icon color="primario" size="48">
            fa-user-circle
          </v-icon>
        </v-col>
        <v-col class="text-end">
          <FormField
            label="Nome do usuário"
            v-model="form.nome"
            :disabled="!editable"
            @blur="updateUserName()"
          />
          <FormField
            label="Empresa"
            v-model="form.nome_empresa"
            class="mb-n5"
            disabled
          />
        </v-col>
        <v-col cols="auto" class="">
          <FormButton outlined color="primario" @click="editable = !editable">
            <v-icon color="primario" size="18">
              fa-pen
            </v-icon>
          </FormButton>
        </v-col>
      </v-row>
    </Panel>
    <Panel lineColor="var(--primary-color)" class="mt-5 text-start">
      <h4>Configuração de senha</h4>
      <LineDivider />
      <v-row>
        <v-col>
          <FormField
            label="Senha atual"
            v-model="form.senhaAtual"
            placeholder="Digite sua senha atual"
            typeField="password"
            :validation="$v.form.senhaAtual"
          />
          <FormField
            label="Nova senha"
            placeholder="Digite sua nova senha"
            v-model="form.senha"
            typeField="password"
            :validation="$v.form.senha"
          />
          <FormField
            label="Confirme a nova senha"
            placeholder="Confirme sua nova senha"
            v-model="form.confirmeSenha"
            typeField="password"
            :validation="$v.form.confirmeSenha"
          />
        </v-col>
        <v-col cols="12" class="text-end">
          <FormButton text="Salvar" @click="updateUserPassword()" />
        </v-col>
      </v-row>
    </Panel>
    <Panel lineColor="var(--primary-color)" class="mt-5 text-start">
      <h4>Integrações</h4>
      <LineDivider />
      <v-row>
        <v-col>
          <FormField
            label="ID do telegram"
            v-model="form.idTelegram"
            typeField="number"
            class="mb-n3"
            help="Digite o ID do telegram e clique para adicionar"
          />
        </v-col>
        <v-col cols="auto">
          <FormButton text="Adicionar" @click="addIDTelegram()" />
        </v-col>
      </v-row>
      <v-row v-for="(id, i) in form.idsTelegram" :key="i">
        <v-col>
          <FormField
            label="ID do telegram"
            :value="id"
            typeField="number"
            class="mb-n5"
            readonly
          />
        </v-col>
        <v-col cols="auto">
          <FormButton
            text="Excluir"
            type="error"
            @click="removeIDTelegram(i)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-end">
          <FormButton text="Salvar" @click="updateUserIntegrations()" />
        </v-col>
      </v-row>
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import LineDivider from '@/components/LineDivider'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {
  USUARIO_LOAD_PROFILE,
  USUARIO_UPDATE_INTEGRATIONS,
  USUARIO_UPDATE_PASSWORD,
  USUARIO_UPDATE_NAME
} from '@/store/actions/usuario'

export default {
  name: 'MeuUsuario',
  components: {
    Panel,
    FormButton,
    FormField,
    LineDivider
  },
  data: () => ({
    editable: false,
    isLoading: false,
    form: {
      idTelegram: '',
      idsTelegram: []
    }
  }),
  validations: {
    form: {
      senha: { required },
      senhaAtual: { required },
      confirmeSenha: { required }
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'getEmpresa'])
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$store
        .dispatch(USUARIO_LOAD_PROFILE)
        .then(data => {
          let idsTelegram = []
          if (data && data.bot_atendimento && data.bot_atendimento.telegram) {
            idsTelegram = data.bot_atendimento.telegram || []
          }
          this.form = {
            ...data,
            ...this.getEmpresa,
            idsTelegram: idsTelegram
          }
          this.isLoading = false
        })
        .catch(err => {
          const erro = err.response || 'Erro ao atualizar usuário'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    updateUserPassword() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.form.senha != this.form.confirmeSenha) {
        this.$vueOnToast.pop(
          'error',
          'A senha informada é diferente da confimação de senha!'
        )
        return
      }
      this.isLoading = true
      this.$store
        .dispatch(USUARIO_UPDATE_PASSWORD, {
          oldPass: this.form.senhaAtual,
          newPass: this.form.senha
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Senha alterada com sucesso!')
          this.isLoading = false
        })
        .catch(err => {
          const erro = err.response.data || 'Erro ao atualizar senha do usuário'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    updateUserName() {
      if (!this.form.nome) {
        this.$vueOnToast.pop('error', 'O nome do usuário é obrigatório!')
        return
      }
      this.isLoading = true
      this.$store
        .dispatch(USUARIO_UPDATE_NAME, {
          idusuario: this.form.idusuario,
          nome: this.form.nome
        })
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Nome do usuário atualizado com sucesso!'
          )
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.error || 'Erro ao atualizar nome do usuário'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    updateUserIntegrations() {
      this.isLoading = true
      this.$store
        .dispatch(USUARIO_UPDATE_INTEGRATIONS, {
          telegram: this.form.idsTelegram
        })
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Integrações atualizadas com sucesso!'
          )
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response || 'Erro ao atualizar integrações do usuário'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    addIDTelegram() {
      if (!this.form.idTelegram || this.form.idTelegram == '') return
      if (this.form.idsTelegram.includes(this.form.idTelegram)) {
        this.$vueOnToast.pop('error', 'ID já inserido na lista!')
        return
      }
      this.form.idsTelegram.push(this.form.idTelegram)
      this.form.idTelegram = ''
    },
    removeIDTelegram(i) {
      this.form.idsTelegram.splice(i)
    }
  },
  created() {
    this.getData()
  }
}
</script>
